<template>
  <div class="about-container solution-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">Our Services</h1>
        <p class="fs-20">
          We tailor to the needs of our <b class="fs-20">customers</b> while
          maintaining one of the highest levels of customer service in the
          industry.
        </p>
      </div>
      <!-- <ul class="df">
        <li>
          <p>
            <a :to="{ name: 'Solution' }">Marine transport business</a>
          </p>
        </li>
        <li>
          <p>
            <a :to="{ name: 'Solution' }">Air freight business</a>
          </p>
        </li>
        <li>
          <p>
            <a :to="{ name: 'Solution' }">Storage</a>
          </p>
        </li>
        <li>
          <p>
            <a :to="{ name: 'Solution' }">Electricity package</a>
          </p>
        </li>
        <li>
          <p>
            <a :to="{ name: 'Solution' }">International Express line</a>
          </p>
        </li>
      </ul> -->
      <div class="banner"></div>
      <div class="third">
        <div class="df ptb-100">
          <div><img src="../assets/img/home/cb2.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">Air Freight Service</h2>
            <p class="fs-20">
              With our expertise,uncompromising service, and competitive rates,
              LeadSpeed intl provides in-depth know-how as well as a broad
              spectrum of flexible and reliable services for air freight
              transports. Whether you need express air assistance, door to door
              service or most any type of accommodation request, we can arrange
              the best shipping options to meet your specific needs for safety,
              time, and convenience.
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">Ocean Freight Service</h2>
            <p class="fs-20">
              With decades of experience，Lead Speed intl offers a superior
              means of transporting your goods by sea. Through an extensive
              network of connections with sea carriers we are able to deliver a
              fast, cost-efficient and reliable ocean freight services.
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/home/cb1.jpg" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/home/cb3.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">Other service</h2>
            <p class="fs-20">Railway</p>
            <p class="fs-20">Trucking</p>
            <p class="fs-20">Custom Brokerage</p>
            <p class="fs-20">Warehouse storage&Distribution</p>
            <p class="fs-20">Stuffing&Lashing</p>
            <p class="fs-20">Insurance</p>
          </div>
        </div>
        <!-- <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">Electricity package</h2>
            <p class="fs-20">
              Electricity package.Electricity package.Electricity
              package.Electricity package.Electricity package.Electricity
              package.Electricity package.Electricity package.Electricity
              package.Electricity package.Electricity package.Electricity
              package.Electricity package.
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/home/cb4.jpg" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/home/cb5.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">International Express line</h2>
            <p class="fs-20">
              International Express line.International Express
              line.International Express line.International Express
              line.International Express line.International Express
              line.International Express line.International Express
              line.International Express line.International Express
              line.International Express line.International Express
              line.International Express line.International Express
              line.International Express line.
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Solution",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
.solution-container .header .fr > ul {
  li.el-menu-item:nth-child(2),
  li li:nth-child(2) {
    background-color: #f47a2c;
    color: white;
    a {
      color: white;
    }
  }
}
</style>
